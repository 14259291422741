<template>
  <GeneralDialogTemplate no-padding>
    <template slot="header">
      <span class="font-weight-medium">Liitumiseks vali aadress</span>
    </template>
    <v-list>
      <v-list-item
        v-for="(email, index) in emailAddresses"
        :key="index"
        :disabled="alreadyInList.includes(email)"
        @click="onJoinEmailClick(email)"
      >
        <v-list-item-icon>
          <v-icon
            :disabled="alreadyInList.includes(email)"
            color="pohjala_blue"
          >
            {{ mdiEmail }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ email }}
            <span v-if="alreadyInList.includes(email)"> (listi liige) </span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </GeneralDialogTemplate>
</template>
<script>
import { computed } from '@vue/composition-api';
import { mdiEmail } from '@mdi/js';
import GeneralDialogTemplate from '@/components/templates/GeneralDialogTemplate.vue';
import groupModule from '@/store/modules/group';
import profileModule from '@/store/modules/profile';
import dialogModule from '@/store/modules/dialog';

const vectorIcons = { mdiEmail };
export default {
  components: { GeneralDialogTemplate },
  setup() {
    const { currentUserProfile } = profileModule();
    const { addGroupMember } = groupModule();
    const {
      dialogPayload, closeDialog, setSnackBarOptions, openSnackBar,
    } = dialogModule();
    const currentGroupId = computed(() => dialogPayload.value.groupId);
    const emailAddresses = computed(() => {
      if (currentUserProfile.value && currentUserProfile.value.email_addresses) {
        return currentUserProfile.value.email_addresses;
      }
      return [];
    });
    const alreadyInList = computed(() => {
      if (currentUserProfile.value && currentUserProfile.value.group_memberships) {
        if (currentUserProfile.value.group_memberships[currentGroupId.value]) {
          return currentUserProfile.value.group_memberships[currentGroupId.value];
        }
      }
      return [];
    });
    const onJoinEmailClick = async (address) => {
      closeDialog();
      try {
        await addGroupMember({
          email: address,
          groupId: currentGroupId.value,
        });
        setSnackBarOptions({
          text: `${address} edukalt lisatud!`,
          color: 'success',
        });
        openSnackBar();
      } catch (err) {
        setSnackBarOptions({
          text: `${address} lisamine ebaõnnestus!`,
          color: 'error',
        });
        openSnackBar();
      }
    };
    return {
      ...vectorIcons,
      onJoinEmailClick,
      emailAddresses,
      alreadyInList,
    };
  },
};
</script>
